import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Subscription, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { filter } from 'rxjs/operators';
import { MediaChange, MediaObserver } from '@angular/flex-layout';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {

  media$: Observable<MediaChange[]>;

  sidebarOpen = true;
  constructor(public afAuth: AngularFireAuth, public mediaObserver: MediaObserver) {
  }

  ngOnInit(): void {

    this.mediaObserver.asObservable()
    .pipe(filter((changes: MediaChange[]) => changes.length > 0),
    map((changes: MediaChange[]) => changes[0])
    ).subscribe((change: MediaChange) => {
      if (change.mqAlias === 'xs'){
        this.sidebarOpen = false;
      }
    });
  }
  sideBarToggle($event): void {
    this.sidebarOpen = !this.sidebarOpen;
  }
}
