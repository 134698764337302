import { Component, Inject , OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-leads-dialog',
  template: `
    <h1 mat-dialog-title *ngIf="mode === 'new'">Create New Lead</h1>
    <h1 mat-dialog-title *ngIf="mode === 'update'">Update Lead</h1>
    <div mat-dialog-content>
    <mat-horizontal-stepper [linear]="false" #stepper>
  <mat-step [stepControl]="companyDetailsFormGroup" [editable]="true">
    <form [formGroup]="companyDetailsFormGroup">
      <ng-template matStepLabel>Company Information</ng-template>
      <mat-form-field>
        <mat-label>Company Name</mat-label>
        <input matInput formControlName="companyNameCtrl" placeholder="Company Name" required>
      </mat-form-field>
      <mat-form-field>
        <mat-label>DBA Name (If different) </mat-label>
        <input matInput formControlName="dbaNameCtrl" placeholder="DBA Name (If different)">
      </mat-form-field>
      <mat-form-field>
          <mat-select formControlName="statusCtrl" placeholder="Status">
            <mat-option *ngFor="let status of statuses" [value]="status.name">
              {{status.name}}
            </mat-option>
          </mat-select>
      </mat-form-field>
      <p><mat-form-field>
        <mat-label>Contact Number</mat-label>
        <input matInput formControlName="contactNumberCtrl" placeholder="Contact Number">
      </mat-form-field></p>
      <p><mat-form-field>
        <mat-label>Lead Number</mat-label>
        <input matInput formControlName="leadNoCtrl" placeholder="Lead Number">
      </mat-form-field></p>
        <mat-form-field>
          <mat-select formControlName="typeofBusinessCtrl" placeholder="Type of Business">
            <mat-option *ngFor="let bustype of bustypes" [value]="bustype.name">
              {{bustype.name}}
            </mat-option>
          </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>FIEN</mat-label>
        <input matInput formControlName="FEINCtrl" placeholder="FIEN">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Date Of Incorp</mat-label>
        <input matInput formControlName="dateOfIncorpCtrl" placeholder="Date Of Incorp">
      </mat-form-field>
      <mat-form-field>
        <mat-label>State Of Incorp</mat-label>
        <input matInput formControlName="stateOfIncorpCtrl" placeholder="State Of Incorp">
      </mat-form-field>
      <h3>Volumes</h3>
      <mat-form-field>
        <mat-label>Monthly Volume</mat-label>
        <input matInput formControlName="monthlyVolumeCtrl" placeholder="Monthly Volume">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Average Transaction</mat-label>
        <input matInput formControlName="atvCtrl" placeholder="Average Transaction">
      </mat-form-field>
      <div>
        <button mat-button matStepperNext>Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="companyAddressFormGroup" [editable]="true">
    <form [formGroup]="companyAddressFormGroup">
      <ng-template matStepLabel>Company Address</ng-template>
      <mat-form-field>
        <mat-label>Address Line 1</mat-label>
        <input matInput formControlName="addressLine1Ctrl" placeholder="Address Line 1">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Address Line 2</mat-label>
        <input matInput formControlName="addressLine2Ctrl" placeholder="Address Line 2">
      </mat-form-field>
      <p><mat-form-field>
        <mat-label>City</mat-label>
        <input matInput formControlName="cityCtrl" placeholder="City">
      </mat-form-field>
      <mat-form-field>
        <mat-label>State</mat-label>
        <input matInput formControlName="stateCtrl" placeholder="State">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Zip Code</mat-label>
        <input matInput formControlName="zipCodeCtrl" placeholder="Zip Code">
      </mat-form-field></p>
      <div>
        <button mat-button matStepperNext>Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="contactInformationFormGroup" [editable]="true">
    <form [formGroup]="contactInformationFormGroup">
      <ng-template matStepLabel>Company Contact (Main Signer)</ng-template>
      <p><mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="contactNameCtrl" placeholder="Name">
      </mat-form-field></p>
      <mat-form-field>
        <mat-label>DL Number</mat-label>
        <input matInput formControlName="contactdlNumberCtrl" placeholder="DL Number">
      </mat-form-field>
      <mat-form-field>
        <mat-label>DL State</mat-label>
        <input matInput formControlName="contactdlStateOfIssueCtrl" placeholder="DL State">
      </mat-form-field>
      <mat-form-field>
        <mat-label>DL Expiry</mat-label>
        <input matInput formControlName="contactdlExpDateCtrl" placeholder="DL Expiry">
      </mat-form-field>
      <p><mat-form-field>
        <mat-label>Title</mat-label>
        <input matInput formControlName="contactTitleCtrl" placeholder="Title">
      </mat-form-field></p>
      <p><mat-form-field>
        <mat-label>Percentage Ownership</mat-label>
        <input matInput formControlName="contactpercentageOfOwnershipCtrl" placeholder="Percentage Ownership">
      </mat-form-field>
      <mat-form-field>
        <mat-label>SSN</mat-label>
        <input matInput formControlName="contactSSNCtrl" placeholder="SSN">
      </mat-form-field>
      <mat-form-field>
        <mat-label>DOB</mat-label>
        <input matInput formControlName="contactDOBCtrl" placeholder="DOB">
      </mat-form-field></p>
      <p><mat-form-field>
        <mat-label>Email Address</mat-label>
        <input matInput type="email" formControlName="contactpercentageOfOwnershipCtrl" placeholder="Email Address">
      </mat-form-field></p>
      <div>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="contactAddressFormGroup" [editable]="true">
    <form [formGroup]="contactAddressFormGroup">
      <ng-template matStepLabel>Contact Address</ng-template>
      <mat-form-field>
        <mat-label>Address Line 1</mat-label>
        <input matInput formControlName="cAddressLine1Ctrl" placeholder="Address Line 1">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Address Line 2</mat-label>
        <input matInput formControlName="cAddressLine2Ctrl" placeholder="Address Line 2">
      </mat-form-field>
      <p><mat-form-field>
        <mat-label>City</mat-label>
        <input matInput formControlName="cCityCtrl" placeholder="City">
      </mat-form-field>
      <mat-form-field>
        <mat-label>State</mat-label>
        <input matInput formControlName="cStateCtrl" placeholder="State">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Zip Code</mat-label>
        <input matInput formControlName="cZipCodeCtrl" placeholder="Zip Code">
      </mat-form-field></p>
      <div>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="documentsFormGroup" [editable]="true">
      <ng-template matStepLabel>Upload Documentation</ng-template>

      <div *ngFor="let uploadedFile of uploadedFiles">
        <button mat-button (click)="getDocument(uploadedFile)" color="accent">{{uploadedFile}}</button>
        <button mat-button (click)="deleteFile(uploadedFile)" color="accent">Delete</button>
      </div>

      <P>
      <input type="file" (change)="detectFiles($event)" multiple>
      <button mat-button [disabled]="!selectedFiles" (click)="uploadFiles()">Upload</button>
      <div *ngFor="let progress of progressarr">
        <progress [value]="progress" max="100"></progress>
        {{ progress | number }}%
      </div>
      <div>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Done</ng-template>
    <h1>Thank you for completing the New Lead Wizard. You can now Create your lead</h1>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button (click)="stepper.reset()">Reset</button>
      <button mat-button (click)="onNoClick()">Cancel</button>
      <button mat-button (click)="createNewLead()" *ngIf="mode === 'new'">Create</button>
      <button mat-button (click)="updateLead()" *ngIf="mode === 'update'">Save</button>
    </div>
  </mat-step>
</mat-horizontal-stepper>
  `,
  styles: [
  ]
})
export class LeadsDialogComponent implements OnInit {

  bustypes: any[];
  statuses: any[];
  selectedFiles: FileList;
  uploadedFiles: any = [];
  docsBase: any = '/Leads_Docs';
  progressarr: any = [];
  mode: string;

  companyDetailsFormGroup: FormGroup;
  companyAddressFormGroup: FormGroup;
  contactInformationFormGroup: FormGroup;
  contactAddressFormGroup: FormGroup;
  documentsFormGroup: FormGroup;

  constructor(public dialogRef: MatDialogRef<LeadsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              private afs: AngularFirestore,
              private afst: AngularFireStorage,
              private snackBar: MatSnackBar,
              private afAuth: AngularFireAuth
  ) {
    this.mode = data.mode;

    this.bustypes = [
      { name: 'MOTO' },
      { name: 'ECOMM' },
      { name: 'POS' }
    ];

    this.statuses = [
      { name: 'NEW' },
      { name: 'REVIEWED' },
      { name: 'IN APPLICATION' },
      { name: 'APPROVED' },
      { name: 'DECLINED' },
      { name: 'LIVE' }
    ];
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async getDocument(docName: any): Promise<any>{
    try{
      const storage = this.afst.storage;
      const file = docName;
      const path = storage.ref(file);

      location = await path.getDownloadURL();

      return location;
    }catch (e){
      console.log(e);
      return ;
    }
  }

  deleteFile(docName: any): void {
    const storageRef = firebase.storage().ref();
    const deleteTask = storageRef.child(`${docName}`).delete().then(() => {
      this.uploadedFiles = this.uploadedFiles.filter(e => e !== docName);
      const documentsForms = this.uploadedFiles;
      // If we are updating also reset the docments array in firestore
      if (this.mode === 'update') {
        this.afs.collection('leads')
          .doc(this.data.lead.id)
          .update({documentsForms})
            .then(() => {
              this.snackBar.open('Document Successfully Deleted', '', {duration: 5000 });
            }).catch((error) => {
              this.snackBar.open('Error Saving : ' + error, '', {duration: 5000 });
            });
          }
    }).catch((error) => {
      this.snackBar.open('Error Deleting : ' + error, '', {duration: 5000 });
      console.log(error);
    });
  }

  detectFiles(event): void {
    console.log('detectFiles: ' + event);
    this.selectedFiles = event.target.files;
  }

  uploadFiles(): void {
    this.uploadedFiles = [];
    const files = this.selectedFiles;

    const storageRef = firebase.storage().ref();

    Array.from(files).forEach(file => {
      this.progressarr.push(0);
    });

    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      console.log(file);
      const tstamp = new Date().getTime();
      const uploadTask = storageRef.child(`${this.docsBase}/${tstamp}/${file.name}`).put(file);

      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          this.progressarr[i] = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error);
        },
        () => {
          console.log('Upload Success : ' + file.name);
          this.uploadedFiles.push(`${this.docsBase}/${tstamp}/${file.name}`);
        });
    }
  }

  async updateLead(): Promise<void> {
    const self = this;
    const companyDetails = self.companyDetailsFormGroup.value;
    const companyAddress = self.companyAddressFormGroup.value;
    const contactInformation = self.contactInformationFormGroup.value;
    const contactAddress = self.contactAddressFormGroup.value;
    const documentsForms = this.uploadedFiles;

    const user = await this.afAuth.currentUser;

    this.afs.collection('leads')
      .doc(self.data.lead.id)
      .set({companyDetails,
        companyAddress,
        contactInformation,
        contactAddress,
        documentsForms,
        uid: user.uid})
        .then(() => {
          this.snackBar.open('Lead Details Saved...', '', {duration: 5000 });
          self.dialogRef.close({companyDetails, companyAddress, contactInformation, contactAddress, documentsForms});
        }).catch((error) => {
          console.log('Error Saving : ' + error);
          this.snackBar.open('Error Saving : ' + error, '', {duration: 5000 });
        });

  }

  async createNewLead(): Promise<void> {
    const companyDetails = this.companyDetailsFormGroup.value;
    const companyAddress = this.companyAddressFormGroup.value;
    const contactInformation = this.contactInformationFormGroup.value;
    const contactAddress = this.contactAddressFormGroup.value;
    const documentsForms = this.uploadedFiles;

    // Apply this to self to be able to
    // access close dialog from callback
    const user = await this.afAuth.currentUser;

    const self = this;

    this.afs.collection('leads').add({
        companyDetails,
        companyAddress,
        contactInformation,
        contactAddress,
        documentsForms,
        uid: user.uid
    }).then(() => {
      this.snackBar.open('Lead Details Saved...', '', {duration: 5000 });
      self.dialogRef.close({companyDetails, companyAddress, contactInformation, contactAddress, documentsForms});
    }).catch((error) => {
      this.snackBar.open('Error Saving : ' + error, '', {duration: 5000 });
    });
  }

  ngOnInit(): void {
    const formData = this.data;
    if (formData.mode === 'new'){
      this.companyDetailsFormGroup = this.fb.group({
        companyNameCtrl: ['', Validators.required],
        dbaNameCtrl: [''],
        contactNumberCtrl: [''],
        typeofBusinessCtrl: [''],
        statusCtrl: ['NEW'],
        leadNoCtrl: [''],
        FEINCtrl: [''],
        dateOfIncorpCtrl: [''],
        stateOfIncorpCtrl: [''],
        atvCtrl: [''],
        monthlyVolumeCtrl: ['']
      });
      this.companyAddressFormGroup = this.fb.group({
        addressLine1Ctrl: [''],
        addressLine2Ctrl: [''],
        cityCtrl: [''],
        stateCtrl: [''],
        zipCodeCtrl: ['']
      });
      this.contactInformationFormGroup = this.fb.group({
        contactNameCtrl: [''],
        contactAddressLine1Ctrl: [''],
        contactAddressLine2Ctrl: [''],
        contactCityCtrl: [''],
        contactStateCtrl: [''],
        contactZipCodeCtrl: [''],
        contactdlNumberCtrl: [''],
        contactdlStateOfIssueCtrl: [''],
        contactdlExpDateCtrl: [''],
        contactTitleCtrl: [''],
        contactpercentageOfOwnershipCtrl: [''],
        contactSSNCtrl: [''],
        contactDOBCtrl: [''],
        contactEmailAddress: ['', Validators.email]
      });
      this.contactAddressFormGroup = this.fb.group({
        cAddressLine1Ctrl: [''],
        cAddressLine2Ctrl: [''],
        cCityCtrl: [''],
        cStateCtrl: [''],
        cZipCodeCtrl: ['']
      });
      this.documentsFormGroup = this.fb.group({
        documentsCtrl: ['']
      });
    }
    if (formData.mode === 'update'){
      this.companyDetailsFormGroup = this.fb.group({
        companyNameCtrl: [formData.lead.companyDetails.companyNameCtrl, Validators.required],
        dbaNameCtrl: [formData.lead.companyDetails.dbaNameCtrl],
        contactNumberCtrl: [formData.lead.companyDetails.contactNumberCtrl],
        typeofBusinessCtrl: [formData.lead.companyDetails.typeofBusinessCtrl],
        statusCtrl: [formData.lead.companyDetails.statusCtrl],
        leadNoCtrl: [formData.lead.id.substr(formData.lead.id.length - 6)],
        FEINCtrl: [formData.lead.companyDetails.FEINCtrl],
        dateOfIncorpCtrl: [formData.lead.companyDetails.dateOfIncorpCtrl],
        stateOfIncorpCtrl: [formData.lead.companyDetails.stateOfIncorpCtrl],
        atvCtrl: [formData.lead.companyDetails.atvCtrl],
        monthlyVolumeCtrl: [formData.lead.companyDetails.monthlyVolumeCtrl]
      });
      this.companyAddressFormGroup = this.fb.group({
        addressLine1Ctrl: [formData.lead.companyAddress.addressLine1Ctrl],
        addressLine2Ctrl: [formData.lead.companyAddress.addressLine2Ctrl],
        cityCtrl: [formData.lead.companyAddress.cityCtrl],
        stateCtrl: [formData.lead.companyAddress.stateCtrl],
        zipCodeCtrl: [formData.lead.companyAddress.zipCodeCtrl]
      });
      this.contactInformationFormGroup = this.fb.group({
        contactNameCtrl: [formData.lead.contactInformation.contactNameCtrl],
        contactAddressLine1Ctrl: [formData.lead.contactInformation.contactAddressLine1Ctrl],
        contactAddressLine2Ctrl: [formData.lead.contactInformation.contactAddressLine2Ctrl],
        contactCityCtrl: [formData.lead.contactInformation.contactCityCtrl],
        contactStateCtrl: [formData.lead.contactInformation.contactStateCtrl],
        contactZipCodeCtrl: [formData.lead.contactInformation.contactZipCodeCtrl],
        contactdlNumberCtrl: [formData.lead.contactInformation.contactdlNumberCtrl],
        contactdlStateOfIssueCtrl: [formData.lead.contactInformation.contactdlStateOfIssueCtrl],
        contactdlExpDateCtrl: [formData.lead.contactInformation.contactdlExpDateCtrl],
        contactTitleCtrl: [formData.lead.contactInformation.contactTitleCtrl],
        contactpercentageOfOwnershipCtrl: [formData.lead.contactInformation.contactpercentageOfOwnershipCtrl],
        contactSSNCtrl: [formData.lead.contactInformation.contactSSNCtrl],
        contactDOBCtrl: [formData.lead.contactInformation.contactDOBCtrl],
        contactEmailAddress: [formData.lead.contactInformation.contactEmailAddress, Validators.email]
      });
      this.contactAddressFormGroup = this.fb.group({
        cAddressLine1Ctrl: [formData.lead.contactAddress.cAddressLine1Ctrl],
        cAddressLine2Ctrl: [formData.lead.contactAddress.cAddressLine2Ctrl],
        cCityCtrl: [formData.lead.contactAddress.cCityCtrl],
        cStateCtrl: [formData.lead.contactAddress.cStateCtrl],
        cZipCodeCtrl: [formData.lead.contactAddress.cZipCodeCtrl]
      });
      this.uploadedFiles = formData.lead.documentsForms;
    }
  }

}
