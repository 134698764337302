import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  constructor(public afAuth: AngularFireAuth, private router: Router) {}

  ngOnInit(): void {
  }

  logOff(): void {
    this.afAuth.signOut().then(() => {
      this.router.navigateByUrl('/login');
    });
  }

}
