
<mat-nav-list>
  <div *ngIf="afAuth.authState | async as user">
    <div class="profile-card">
      <div class="header">
        <h2> {{user.displayName}} </h2>
        <h4> {{user.email}} </h4>
      </div>
      <img [src]="user.photoURL || 'assets/images/default-user.svg'" alt="">
    </div>
  </div>
  <mat-divider></mat-divider>
  <div mat-subheader>Navigation Menu</div>
  <div *ngIf="(afAuth.authState | async)">
    <a mat-list-item routerLink="/" routerLinkActive="router-link-active">
      <mat-icon>home</mat-icon>
      Dashboard
    </a>
    <a mat-list-item routerLink="/leads" routerLinkActive="router-link-active">
      <mat-icon>contact_phone</mat-icon>
      Deal Comm
    </a>
    <a mat-list-item routerLink="/posts" routerLinkActive="router-link-active">Posts</a>
    <mat-divider></mat-divider>
    <div mat-subheader>Resources</div>
    <a mat-list-item routerLink="/rescal" routerLinkActive="router-link-active">
      <mat-icon>calculate</mat-icon>
      Residual Calculator
    </a>
    <a mat-list-item routerLink="/savings" routerLinkActive="router-link-active">
      <mat-icon>credit_card</mat-icon>
      Merchant Savings Calculator
    </a>
    <a mat-list-item routerLink="/docs" routerLinkActive="router-link-active">
      <mat-icon>text_snippet</mat-icon>
      Templates and Documents
    </a>
    <a mat-list-item (click)="logOff()">
      <mat-icon>exit_to_app</mat-icon>
      Logout
    </a>
  </div>
  <div *ngIf="!(afAuth.authState | async)">
    <a mat-list-item routerLink="/login" routerLinkActive="router-link-active">
      <mat-icon>login</mat-icon>
      Login
    </a>
  </div>
</mat-nav-list>

