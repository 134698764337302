import { Component, Inject , OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { formatCurrency } from '@angular/common';

@Component({
  selector: 'app-savings-result-dialog',
  template: `
    <h2 mat-dialog-title>Merchant Savings</h2>
    <div mat-dialog-content>
      <table>
        <tr>
          <td>Total Monthly Savings: </td>
          <td class="pull-right">{{formatCurrency(data.totalMonthlySavings)}}</td>
        </tr>
        <tr>
          <td>Total Annual Savings: </td>
          <td class="pull-right">{{formatCurrency(data.totalAnnualSavings)}}</td>
        </tr>
      </table>
    </div>
    <div mat-dialog-actions>
      <button mat-button mat-dialog-close>Close</button>
    </div>
  `,
  styles: [`
    .pull-right { text-align: right; }
  `]
})
export class SavingsResultDialogComponent {

  constructor(public dialogRef: MatDialogRef<SavingsResultDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any
    ){ }

    formatCurrency(amount: number): string {
      return formatCurrency(amount, 'en-US', '$');
    }

}
