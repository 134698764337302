import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import * as firebase from 'firebase/app';

interface FoodNode {
  name: string;
  link: string;
  children?: FoodNode[];
}

/*var TREE_DATA: FoodNode[] = [
  {
    name: 'Product Documents',
    link: '',
    children: [
      {name: 'Clover Documents',
      link: '',
      children: [
        {name: 'Clover Application', link: 'doc.pdf'},
        {name: 'Clover Mini Sheets', link: 'doc.pdf'},
      ]
    }
    ]
  },
  {
    name: 'Sales Templates',
    link: '',
    children: [
      {name: 'Sales Email Accelerator',
      link: 'doc.pdf',
      },
      {name: 'Sales Chat Accelerator',
      link: 'doc.pdf',
      }
    ]
  }
];*/

let TREE_DATA = [];

const delay = ms => new Promise(res => setTimeout(res, ms));

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'app-docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.scss']
})
export class DocsComponent implements OnInit {

  async getDocument(docName: any): Promise<any>{
    try{
      const file = docName;
      const path = firebase.storage().ref(file);

      location = await path.getDownloadURL();

      return location;
    }catch (e){
      console.log(e);
      return ;
    }
  }

  private findChildren = (fullpath: string, node: FoodNode) => {
    const storageRef = firebase.storage().ref(fullpath);
    storageRef.listAll().then((result) => {
      result.prefixes.forEach((imageRef) => {
        console.log(imageRef.name);

        const tmpnode = {} as FoodNode;
        tmpnode.name = (imageRef.name);
        tmpnode.link = '';
        tmpnode.children = [];
        node.children.push(tmpnode);

        // Force a reload of the data
        this.dataSource.data = TREE_DATA;

        this.findChildren(fullpath + '/' + imageRef.name, tmpnode);
      });

      result.items.forEach((imageRef) => {
        console.log(imageRef.name);
        const tmpnode = {} as FoodNode;
        tmpnode.name = imageRef.name;
        tmpnode.link = fullpath + '/' + imageRef.name;
        console.log('link:' + tmpnode.link);
        node.children.push(tmpnode);
        this.dataSource.data = TREE_DATA;
      });
    }).catch((error) => {
      console.log(error);
    });
  }

  ngOnInit(): void {

    TREE_DATA = [];

    const storageRef = firebase.storage().ref('');

    storageRef.listAll().then((result) => {
      result.prefixes.forEach((imageRef) => {
        console.log(imageRef.name);

        const node = {} as FoodNode;
        node.name = imageRef.name;
        node.link = '';
        node.children = [];
        TREE_DATA.push(node);
        const index = TREE_DATA.length;

        this.dataSource.data = TREE_DATA;

        this.findChildren(imageRef.name, TREE_DATA[ index - 1 ]);
      });
    }).catch((error) => {
      console.log(error);
    });

  }

  constructor() {
    this.dataSource.data = TREE_DATA;
  }

  // tslint:disable-next-line: variable-name
  private _transformer = (node: FoodNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      link: node.link,
      level,
    };
  }

  // tslint:disable-next-line: member-ordering
  treeControl = new FlatTreeControl<ExampleFlatNode>(node => node.level, node => node.expandable);

  // tslint:disable-next-line: member-ordering
  treeFlattener = new MatTreeFlattener(this._transformer, node => node.level, node => node.expandable, node => node.children);

  // tslint:disable-next-line: member-ordering
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
}
