import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './layouts/default/default.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { DocsComponent } from './modules/docs/docs.component';
import { LeadsComponent } from './modules/leads/leads.component';
import { PostsComponent } from './modules/posts/posts.component';
import { RescalComponent } from './modules/rescal/rescal.component';
import { SavingsCalculatorComponent } from './modules/savings-calculator/savings-calculator.component';
import { AuthGuard } from './modules/user/auth.guard';

const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [{
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'posts',
    component: PostsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule)
  },
  {
    path: 'leads',
    component: LeadsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'rescal',
    component: RescalComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'savings',
    component: SavingsCalculatorComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'docs',
    component: DocsComponent,
    canActivate: [AuthGuard]
  }
]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
