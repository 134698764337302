// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBxj3wpiAZcbIzsdofRE-a6Ho_Gj-h0StQ',
    authDomain: 'payxps-7b523.firebaseapp.com',
    databaseURL: 'https://payxps-7b523.firebaseio.com',
    projectId: 'payxps-7b523',
    storageBucket: 'payxps-7b523.appspot.com',
    messagingSenderId: '607086992211',
    appId: '1:607086992211:web:677f005cd5619be6901fa6',
    measurementId: 'G-WZZ1WEPNPZ'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
