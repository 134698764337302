import { Component, OnInit , Inject } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SavingsResultDialogComponent } from './savings-calculator/savings-result-dialog.component';

@Component({
  selector: 'app-savings-calculator',
  templateUrl: './savings-calculator.component.html',
  styleUrls: ['./savings-calculator.component.scss']
})
export class SavingsCalculatorComponent implements OnInit {

  constructor(private fb: FormBuilder, public dialog: MatDialog) { }

  calculatorFormsGroup: FormGroup;

  ngOnInit(): void {
    this.calculatorFormsGroup = this.fb.group({
      vmdCountCtrl: [''],
      cVmdFeeCtrl: [''],
      nVmdFeeCtrl: [''],
      amexCountCtrl: [''],
      amexVolumeCtrl: [''],
      tier1VolumeCtrl: [''],
      tier2VolumeCtrl: [''],
      tier3VolumeCtrl: [''],
      icPlusVolCtrl: [''],
      cAmexFeeCtrl: [''],
      nAmexFeeCtrl: [''],
      cTier1Ctrl: [''],
      cTier2Ctrl: [''],
      cTier3Ctrl: [''],
      cAmexDiscountCtrl: [''],
      cICPlusCtrl: [''],
      cStatementFee: [''],
      cBatchFeeCtrl: [''],
      nTier1Ctrl: [''],
      nTier2Ctrl: [''],
      nTier3Ctrl: [''],
      nAmexDiscountCtrl: [''],
      nICPlusCtrl: [''],
      nStatementFee: [''],
      nBatchFeeCtrl: ['']
    });
  }

  clickCalculate(): void {

    const formData = this.calculatorFormsGroup.value;
    /*
    const formData = {
      amexCountCtrl: 1234,
      amexValueCtrl: 123456,
      cAmexDiscountCtrl: 2,
      cAmexFeeCtrl: 0.12,
      cBatchFeeCtrl: 234,
      cICPlusCtrl: 2,
      cStatementFee: 234,
      cTier1Ctrl: 2,
      cTier2Ctrl: 2,
      cTier3Ctrl: 2,
      cVmdFeeCtrl: 0.12,
      nAmexDiscountCtrl: 1,
      nAmexFeeCtrl: 0.1,
      nBatchFeeCtrl: 123,
      nICPlusCtrl: 1,
      nStatementFee: 123,
      nTier1Ctrl: 1,
      nTier2Ctrl: 1,
      nTier3Ctrl: 1,
      nVmdFeeCtrl: 0.1,
      vmdCountCtrl: 1234,
      vmdValueCtrl: 123456
    };
    */

    // Initialise the calculated fields
    const calculationData = {
      vmdTxnFeeDiff: formData.cVmdFeeCtrl - formData.nVmdFeeCtrl,
      amexTxnFeeFeeDiff: formData.cAmexFeeCtrl - formData.nAmexFeeCtrl,
      tier1Diff: formData.cTier1Ctrl - formData.nTier1Ctrl,
      tier2Diff: formData.cTier2Ctrl - formData.nTier2Ctrl,
      tier3Diff: formData.cTier3Ctrl - formData.nTier3Ctrl,
      amexDiscountDiff: formData.cAmexDiscountCtrl - formData.nAmexDiscountCtrl,
      icPlusDiff: formData.cICPlusCtrl - formData.nICPlusCtrl,
      statementFeeSavings: formData.cStatementFee - formData.nStatementFee,
      batchFeeSavings: formData.cBatchFeeCtrl - formData.nBatchFeeCtrl,
      vmdTxnFeeSaving: 0,
      amexTxnFeeSaving: 0,
      tier1Savings: 0,
      tier2Savings: 0,
      tier3Savings: 0,
      amexDiscSavings: 0,
      icPlusSavings: 0,
      totalMonthlySavings: 0,
      totalAnnualSavings: 0
    };

    // Calculate the savings fields
    calculationData.vmdTxnFeeSaving = calculationData.vmdTxnFeeDiff * formData.vmdCountCtrl;
    calculationData.amexTxnFeeSaving = calculationData.amexTxnFeeFeeDiff * formData.amexCountCtrl;
    calculationData.tier1Savings = (calculationData.tier1Diff / 100) * formData.tier1VolumeCtrl;
    calculationData.tier2Savings = (calculationData.tier2Diff / 100) * formData.tier2VolumeCtrl;
    calculationData.tier3Savings = (calculationData.tier3Diff / 100) * formData.tier3VolumeCtrl;
    calculationData.amexDiscSavings = (calculationData.amexDiscountDiff / 100) * formData.amexVolumeCtrl;
    calculationData.icPlusSavings = (calculationData.icPlusDiff / 100) * formData.icPlusVolCtrl;
    calculationData.totalMonthlySavings =
      calculationData.vmdTxnFeeSaving +
      calculationData.amexTxnFeeSaving +
      calculationData.tier1Savings +
      calculationData.tier2Savings +
      calculationData.tier3Savings +
      calculationData.amexDiscSavings +
      calculationData.icPlusSavings +
      calculationData.statementFeeSavings +
      calculationData.batchFeeSavings;
    calculationData.totalAnnualSavings = calculationData.totalMonthlySavings * 12;

    const dialogRef = this.dialog.open(SavingsResultDialogComponent, {
      width: '400px',
      data: calculationData
    });

  }

}
