import {AfterViewInit, Component, OnInit} from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { LeadsDialogComponent } from './dialogs/leads-dialog.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import * as firebase from 'firebase/app';

export interface UserData {
  id: string;
  name: string;
  progress: string;
  color: string;
}

@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.scss']
})
export class LeadsComponent implements AfterViewInit, OnInit {
  mediaSub: Subscription;
  leads: any = [];
  steps: any = [];
  products: any = [];
  media$: Observable<MediaChange[]>;
  salesTemplatesPath: any = 'Sales_Templates/';

  constructor(
    public mediaObserver: MediaObserver,
    public dialog: MatDialog,
    private afs: AngularFirestore,
    private afStorage: AngularFireStorage) {

  }

  ngOnInit(): void {
    this.media$ = this.mediaObserver.asObservable();

    const user = firebase.auth().currentUser;

    const rolesDoc = this.afs.firestore.collection('roles').doc(user.uid).get();
    rolesDoc.then((querySnapshotRoles) => {
         const admin = querySnapshotRoles.get('admin');
         let leadsDoc = this.afs.firestore.collection('leads').where('uid', '==', user.uid).orderBy('companyDetails.companyNameCtrl');

         if (admin === true) {
            leadsDoc = this.afs.firestore.collection('leads');
         } else {
         }

         leadsDoc.get().then((querySnapshotLeads) => {
          querySnapshotLeads.forEach((doc) => {
            const lead = { ...doc.data(), id: doc.id, dealNumber: doc.id.substr(doc.id.length - 6), display: true};
            this.leads.push(lead);
          });
        });

    });

    const stepsDoc = this.afs.firestore.collection('steps').orderBy('stepNo');
    stepsDoc.get().then((querySnapshotSteps) => {
      querySnapshotSteps.forEach((doc) => {
        const step = { ...doc.data() };
        this.steps.push(step);
      });
    });

    const productsDoc = this.afs.firestore.collection('products').orderBy('productNo');
    productsDoc.get().then((querySnapshotProducts) => {
      querySnapshotProducts.forEach((doc) => {
        const product = { ...doc.data() };
        this.products.push(product);
      });
    });

  }

  ngAfterViewInit(): void {

  }

  applyFilter(event: Event): void{
    const filterValue = (event.target as HTMLInputElement).value;

    if (filterValue.length === 0) {
      for (const tmplead of this.leads) {
        tmplead.display = true;
      }
      return;
    }

    for (const lead of this.leads) {
      const companyName = lead.companyDetails.companyNameCtrl;
      const dealNo = lead.dealNumber;
      if (companyName.indexOf(filterValue) < 0 && dealNo.indexOf(filterValue) < 0) {
        lead.display = false;
      } else {
        lead.display = true;
      }
    }
  }

  async getDocument(docName: any): Promise<any>{
    try{
      const storage = this.afStorage.storage;
      const file = docName;
      const path = storage.ref(file);

      location = await path.getDownloadURL();

      return location;
    }catch (e){
      console.log(e);
      return ;
    }
  }

  /**
   * Delete lead
   */
  async deleteLead(leadId: string, documents: []): Promise<void> {

    documents.forEach((doc) => {
      const storageRef = firebase.storage().ref();
      storageRef.child(`${doc}`).delete();
    });

    try {
      await this.afs
        .collection('leads')
        .doc(leadId)
        .delete();
    } catch (err) {
      console.log(err);
      return;
    }

    // Remove from array
    for (let i = 0, len = this.leads.length; i < len; i++) {
      if (this.leads[i].id === leadId) {
        this.leads.splice(i, 1);
      }
    }

    return;
  }

  updateLead(lead: object): void{
    const dialogRef = this.dialog.open(LeadsDialogComponent, {
      width: 'auto',
      data: {mode: 'update', lead}
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result){

        this.leads = [];

        const user = firebase.auth().currentUser;

        let leadsDoc: any;

        const rolesDoc = this.afs.firestore.collection('roles').doc(user.uid).get();
        rolesDoc.then((querySnapshotRoles) => {
            const admin = querySnapshotRoles.get('admin');
            leadsDoc = this.afs.firestore.collection('leads').where('uid', '==', user.uid).orderBy('companyDetails.companyNameCtrl');

            if (admin === true) {
                leadsDoc = this.afs.firestore.collection('leads').orderBy('companyDetails.companyNameCtrl');
            } else {
            }

            leadsDoc.get().then((querySnapshotLeads) => {
              querySnapshotLeads.forEach((doc) => {
                const leadDoc = { ...doc.data(), id: doc.id, dealNumber: doc.id.substr(doc.id.length - 6), display: true};
                this.leads.push(leadDoc);
              });
            });
        });
      }
    });
  }

  openDialog(): void {

    const dialogRef = this.dialog.open(LeadsDialogComponent, {
      width: 'auto',
      data: {mode: 'new'}
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result){

        this.leads = [];

        const user = firebase.auth().currentUser;

        let leadsDoc: any;

        const rolesDoc = this.afs.firestore.collection('roles').doc(user.uid).get();
        rolesDoc.then((querySnapshotRoles) => {
            const admin = querySnapshotRoles.get('admin');
            leadsDoc = this.afs.firestore.collection('leads').where('uid', '==', user.uid).orderBy('companyDetails.companyNameCtrl');

            if (admin === true) {
                leadsDoc = this.afs.firestore.collection('leads').orderBy('companyDetails.companyNameCtrl');
            } else {
            }

            leadsDoc.get().then((querySnapshotLeads) => {
              querySnapshotLeads.forEach((doc) => {
                const leadDoc = { ...doc.data(), id: doc.id, dealNumber: doc.id.substr(doc.id.length - 6), display: true};
                this.leads.push(leadDoc);
              });
            });
        });
      }
    });
  }
}
