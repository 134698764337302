<mat-row></mat-row>
<br />
<h1>Merchant Savings Calculator ( Need Help? <a href="https://payxps.com/learning-center/" target="_blank">Learning Center</a>)</h1>
<form [formGroup]="calculatorFormsGroup">
  <mat-divider></mat-divider>
  <div class="content" fxLayout="row">
    <div fxFlex="50">
      <h2>Monthly Transaction Volumes</h2>
      <p>
      <mat-form-field>
        <mat-label>VISA / MC / D Count</mat-label>
        <input matInput type="number" formControlName="vmdCountCtrl" placeholder="VISA / MC / D Count" class="savings-right-align">
      </mat-form-field>

      </p>
      <p>
      <mat-form-field>
        <mat-label>AMEX Count</mat-label>
        <input matInput type="number" formControlName="amexCountCtrl" placeholder="AMEX Count" class="savings-right-align">
      </mat-form-field>
      </p>
    </div>
    <div fxFlex="50">
      <h2>Transaction Fees</h2>
      <p>
        <mat-form-field>
          <mat-label>Current VISA / MC / D Txn Fee</mat-label>
          <input matInput type="number" formControlName="cVmdFeeCtrl" placeholder="Current VISA / MC / D Txn Fee" class="savings-right-align">
          <span matPrefix>$&nbsp;</span>
        </mat-form-field>
        &nbsp;
        <mat-form-field>
          <mat-label>Proposed VISA / MC / D Txn Fee</mat-label>
          <input matInput type="number" formControlName="nVmdFeeCtrl" placeholder="Proposed VISA / MC / D Txn Fee" class="savings-right-align">
          <span matPrefix>$&nbsp;</span>
        </mat-form-field>
        </p>
        <p>
          <mat-form-field>
            <mat-label>Current AMEX Txn Fee</mat-label>
            <input matInput type="number" formControlName="cAmexFeeCtrl" placeholder="Current AMEX Txn Fee" class="savings-right-align">
            <span matPrefix>$&nbsp;</span>
          </mat-form-field>
          &nbsp;
          <mat-form-field>
            <mat-label>Proposed AMEX Txn Fee</mat-label>
            <input matInput type="number" formControlName="nAmexFeeCtrl" placeholder="Proposed AMEX Txn Fee" class="savings-right-align">
            <span matPrefix>$&nbsp;</span>
          </mat-form-field>
          </p>
    </div>
  </div>
  <p>
  <br />
  <mat-divider></mat-divider>
  <h2>Fees</h2>
    <div class="content" fxLayout="row">
      <div fxFlex="50">
        <p>
          <mat-form-field>
            <mat-label>Current Tier 1 Fee</mat-label>
            <input matInput type="number" formControlName="cTier1Ctrl" placeholder="Current Tier 1 Fee" class="savings-right-align">
            <span matSuffix>%</span>
          </mat-form-field>
          &nbsp;
          <mat-form-field>
            <mat-label>Proposed Tier 1 Fee</mat-label>
            <input matInput type="number" formControlName="nTier1Ctrl" placeholder="Proposed Tier 1 Fee" class="savings-right-align">
            <span matSuffix>%</span>
          </mat-form-field>
          &nbsp;
          <mat-form-field>
            <mat-label>Tier 1 Volume</mat-label>
            <input matInput type="number" formControlName="tier1VolumeCtrl" placeholder="Tier 1 Volume" class="savings-right-align">
            <span matPrefix>$&nbsp;</span>
            <span matSuffix>.00</span>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field>
            <mat-label>Current Tier 2 Fee</mat-label>
            <input matInput type="number" formControlName="cTier2Ctrl" placeholder="Current Tier 2 Fee" class="savings-right-align">
            <span matSuffix>%</span>
          </mat-form-field>
          &nbsp;
          <mat-form-field>
            <mat-label>Proposed Tier 2 Fee</mat-label>
            <input matInput type="number" formControlName="nTier2Ctrl" placeholder="Proposed Tier 2 Fee" class="savings-right-align">
            <span matSuffix>%</span>
          </mat-form-field>
          &nbsp;
          <mat-form-field>
            <mat-label>Tier 2 Volume</mat-label>
            <input matInput type="number" formControlName="tier2VolumeCtrl" placeholder="Tier 2 Volume" class="savings-right-align">
            <span matPrefix>$&nbsp;</span>
            <span matSuffix>.00</span>
          </mat-form-field>
          <p>
          <mat-form-field>
            <mat-label>Current Tier 3 Fee</mat-label>
            <input matInput type="number" formControlName="cTier3Ctrl" placeholder="Current Tier 3 Fee" class="savings-right-align">
            <span matSuffix>%</span>
          </mat-form-field>
          &nbsp;
          <mat-form-field>
            <mat-label>Proposed Tier 3 Fee</mat-label>
            <input matInput type="number" formControlName="nTier3Ctrl" placeholder="Proposed Tier 3 Fee" class="savings-right-align">
            <span matSuffix>%</span>
          </mat-form-field>
          &nbsp;
          <mat-form-field>
            <mat-label>Tier 3 Volume</mat-label>
            <input matInput type="number" formControlName="tier3VolumeCtrl" placeholder="Tier 3 Volume" class="savings-right-align">
            <span matPrefix>$&nbsp;</span>
            <span matSuffix>.00</span>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field>
            <mat-label>Current AMEX Discount</mat-label>
            <input matInput type="number" formControlName="cAmexDiscountCtrl" placeholder="Current AMEX Discount" class="savings-right-align">
            <span matSuffix>%</span>
          </mat-form-field>
          &nbsp;
          <mat-form-field>
            <mat-label>Proposed AMEX Discount</mat-label>
            <input matInput type="number" formControlName="nAmexDiscountCtrl" placeholder="Proposed AMEX Discount" class="savings-right-align">
            <span matSuffix>%</span>
          </mat-form-field>
          &nbsp;
          <mat-form-field>
            <mat-label>AMEX Volume</mat-label>
            <input matInput type="number" formControlName="amexVolumeCtrl" placeholder="AMEX Volume" class="savings-right-align">
            <span matPrefix>$&nbsp;</span>
            <span matSuffix>.00</span>
          </mat-form-field>
        </p>
        <h2>OR</h2>
        <p>
          <mat-form-field>
            <mat-label>Current IC Plus Fee</mat-label>
            <input matInput type="number" formControlName="cICPlusCtrl" placeholder="Current IC Fee" class="savings-right-align">
            <span matSuffix>%</span>
          </mat-form-field>
          &nbsp;
          <mat-form-field>
            <mat-label>Proposed IC Plus Fee</mat-label>
            <input matInput type="number" formControlName="nICPlusCtrl" placeholder="Proposed IC Fe" class="savings-right-align">
            <span matSuffix>%</span>
          </mat-form-field>
          &nbsp;
          <mat-form-field>
            <mat-label>IC Plus Volume</mat-label>
            <input matInput type="number" formControlName="icPlusVolCtrl" placeholder="IC Plus Volume" class="savings-right-align">
            <span matPrefix>$&nbsp;</span>
            <span matSuffix>.00</span>
          </mat-form-field>
        </p>
      </div>
      <div fxFlex="50">
        <p>
          <mat-form-field>
            <mat-label>Current Statement Fees</mat-label>
            <input matInput type="number" formControlName="cStatementFee" placeholder="Current Statement Fees" class="savings-right-align">
            <span matPrefix>$&nbsp;</span>
            <span matSuffix>.00</span>
          </mat-form-field>
          &nbsp;
          <mat-form-field>
            <mat-label>Proposed Statement Fees</mat-label>
            <input matInput type="number" formControlName="nStatementFee" placeholder="Proposed Statement Fees" class="savings-right-align">
            <span matPrefix>$&nbsp;</span>
            <span matSuffix>.00</span>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field>
            <mat-label>Current Batch Fees</mat-label>
            <input matInput type="number" formControlName="cBatchFeeCtrl" placeholder="Current Batch Fees" class="savings-right-align">
            <span matPrefix>$&nbsp;</span>
          </mat-form-field>
          &nbsp;
          <mat-form-field>
            <mat-label>Proposed Batch Fees</mat-label>
            <input matInput type="number" formControlName="nBatchFeeCtrl" placeholder="Proposed Batch Fees" class="savings-right-align">
            <span matPrefix>$&nbsp;</span>
          </mat-form-field>
        </p>
      </div>
    </div>
</form>
<button mat-raised-button color="primary" (click)="clickCalculate()">calculate</button>
<br />
&nbsp;
<br />
<mat-divider></mat-divider>
