<mat-toolbar color="primary">
  <mat-toolbar-row>
    <button mat-icon-button (click)="toggleSideBar()">
      <mat-icon>menu</mat-icon>
    </button>
    <span> PAYXPS </span>
    <div fxFlex fxLayout="row" fxLayoutAlign="flex-end">
      <ul fxLayout="row" fxLayoutGap="20px">
        <li>
          <button mat-icon-button>
            <mat-icon>settings</mat-icon>
          </button>
          <button mat-icon-button>
            <mat-icon>help_outline</mat-icon>
          </button>
        </li>
      </ul>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
