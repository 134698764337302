<main>
  <div *ngIf="!(afAuth.authState | async)">
    <h1>Login</h1>

    <button mat-raised-button appGoogleSignin>
      <img src="/assets/images/google-logo.svg" /> Login with Google
    </button>

    <h5>OR</h5>

    <app-email-login></app-email-login>
  </div>

  <div *ngIf="afAuth.authState | async as user" class="logout">
    <p>
      Logged in as <strong>{{ user.displayName }}</strong>
    </p>

    <button mat-stroked-button (click)="logOff()">Logout</button>
  </div>
</main>
