<app-header (toggleSideBarEmitter)="sideBarToggle($event)"></app-header>
<mat-drawer-container>
  <mat-drawer mode="side" [opened]="sidebarOpen">
      <app-sidebar></app-sidebar>
      <app-footer></app-footer>
  </mat-drawer>
  <mat-drawer-content>
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
