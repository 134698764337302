<mat-row></mat-row>
<mat-form-field>
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Begin Typing to filter" #input>
</mat-form-field>
<button mat-button (click)="openDialog()">Add New Lead</button>
<br />
<h1>LEADS</h1>
<div class="content" fxLayout="row wrap" fxLayoutGap="16px grid" fxLayout.xs="column">
  <ng-container *ngFor="let lead of leads">
    <div fxFlex='30' *ngIf="lead.display">
    <mat-card class="example-card">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image">{{lead.companyDetails.typeofBusinessCtrl}}</div>
        <mat-card-title>{{lead.companyDetails.companyNameCtrl}}</mat-card-title>
        <mat-card-subtitle>Deal Number: {{lead.dealNumber}}</mat-card-subtitle>
        <mat-card-subtitle>Status: {{lead.companyDetails.statusCtrl}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
          <div></div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button (click)="updateLead(lead)" color="primary">UPDATE</button>
        <app-delete-button (delete)="deleteLead(lead.id, lead.documentsForms)"></app-delete-button>
      </mat-card-actions>
    </mat-card>
  </div>
  </ng-container>
</div>
<br />
<mat-divider></mat-divider>
<h1>Steps</h1>
<div class="content" fxLayout="row wrap" fxLayoutGap="16px grid" fxLayout.xs="column" >
  <div fxFlex='30' *ngFor="let step of steps">
    <mat-card class="example-card">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>{{step.stepName}}</mat-card-title>
        <mat-card-subtitle>Step Number: {{step.stepNo}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="content" fxLayout="row">
          <div fxFlex="50">
            <mat-icon class="icon">{{step.stepIcon}}</mat-icon>
          </div>
          <div fxFlex="50">
            <div *ngFor="let detailStep of step.detailsSteps">
              <button mat-button (click)="getDocument(detailStep.docName)" color="accent">{{detailStep.name}}</button>
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
<br />
<mat-divider></mat-divider>
<h1>Products</h1>
<div class="content" fxLayout="row wrap" fxLayoutGap="16px grid" fxLayout.xs="column" >
  <div fxFlex='30' *ngFor="let product of products">
    <mat-card class="example-card">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>{{product.productName}}</mat-card-title>
        <mat-card-subtitle>Product Number: {{product.productNo}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="content" fxLayout="row">
          <div fxFlex="50">
            <mat-icon class="icon">{{product.productIcon}}</mat-icon>
          </div>
          <div fxFlex="50">
            <div *ngFor="let detailsProducts of product.detailsProducts">
              <button mat-button (click)="getDocument(detailsProducts.docName)" color="accent">{{detailsProducts.name}}</button>
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
<br />
<br />
<mat-divider></mat-divider>
